<template>	
    <button class="p-link" @click="toggle">
		<i v-badge.danger="this.numero_notificaciones" :class="['pi pi-bell custom-bell', { 'pi-vibrate': vibrate, 'no-notification': this.numero_notificaciones == '' } ]"></i>
	</button>
	<OverlayPanel ref="list" appendTo="body">
		<div class="flex flex-column gap-3 w-25rem">
			<div>
				<span class="text-2xl font-semibold text-secondary">Notificaciones<button class="p-link" @click="goatsc" style="color:white">..</button></span>
			</div>
			<DataTable v-model:selection="selectedNotification" :rowClass="rowClass" :value="notificaciones" selectionMode="single" @row-select="onNotificationSelect">
                <Column>
                    <template #body="slotProps">
						<div class="font-medium">{{ slotProps.data.titulo }}</div>
						<span class="text-sm text-color-secondary">
							<strong>{{ slotProps.data.tarjeta_id }}</strong>. {{ slotProps.data.titulo_tarjeta }}
						</span>
						<div class="text-sm text-color-secondary">{{ slotProps.data.descripcion }}</div>
						<span class="text-sm text-color-secondary">{{ slotProps.data.fecha }}</span>
                    </template>
                </Column>
				<Column>
                    <template #body="slotProps">
                        <MenuNotification :notificacion="slotProps.data" @datos="recibirValor"/>
                    </template>
                </Column>
            </DataTable>      
		</div>		
		<Paginator :rows="5" :totalRecords="totalItems" @page="onPage($event)"></Paginator>
	</OverlayPanel>
	
    <FormTarjeta :tarjeta="tarjeta_notificacion" :tablero_id="tablero_id_notificacion" :tarjetaDialog="visible" @datos="recibirValorTarjeta" />
	
</template>

<script>
import { sendRequestList, createChannelConection, sendRequest } from '@/helper'; 
import MenuNotification from '@/components/MenuNotification.vue';
import FormTarjeta from '@/components/FormTarjeta.vue';

const url = process.env.VUE_APP_BASE_URL;

export default {
	components: {
       MenuNotification,
       FormTarjeta
    },
	data(){
        return{
			notificaciones: [],
			vibrate: false,
			notificacion: null,
			totalItems: 0,
			page: 0,
			selectedNotification: null,
			visible: false,
			tarjeta_notificacion: {},
			tablero_id_notificacion: "",
			numero_notificaciones: "",
			notificationAudio: new Audio(require('@/assets/sounds/notification.mp3')),
			goat: new Audio(require('@/assets/sounds/goat.mp3'))
		}
	},
	methods: {
		playNotificationSound() {
			this.notificationAudio.play();
		},
		goatsc(event) {
			this.goat.play(event);
        },
		async recibirValor(datos) {
            if (datos.ocultar) {
                const result = await sendRequestList(url+"/notificaciones?page="+this.page);
                this.notificaciones = result.data.data;
				this.countNotificaciones();
			}
		},
		async recibirValorTarjeta(datos) {
            this.visible = datos.visible;
            if (datos.columna) {
                this.moveCard(this.tarjeta_notificacion, datos.columna, 0);
            }
        },
		addNotification(data) {
			this.notificaciones.push(data);
			this.vibrate = true;
		},
		toggle(event) {
            this.$refs.list.toggle(event);
			this.vibrate = false;
			this.getNotificaciones();
        },
		async getNotificaciones(){
          const result = await sendRequestList(url+"/notificaciones");
          this.notificaciones = result.data.data;
          this.totalItems = result.data.meta.total;             
        },
		async onPage(event) {
          this.page = event.page + 1;          
          const result = await sendRequestList(url+"/notificaciones?page="+this.page);
          this.notificaciones = result.data.data;
        },
		async onNotificationSelect(event) {
			this.notificacion = event.data;
			const result = await sendRequest('PUT', {}, url+"/leido_notificaciones/"+this.notificacion.id, true);
			this.countNotificaciones();
			if (result.data.tarjeta != null) {
				this.tarjeta_notificacion = result.data.tarjeta;
				this.tablero_id_notificacion = ""+result.data.tablero_id;
				this.visible = true;
			}
		},		
		rowClass(data) {
            return [{ 'surface-50': data.leido == 0 }];
        },
		async countNotificaciones() {
			const result = await sendRequest('GET', {}, url+"/count_notificaciones", true);
			this.numero_notificaciones = result > 0 ? result : "";
		},
		async moveCard(tarjeta, new_column, new_index) {
			var result = "";        
			var params = {
			new_column: new_column,
			new_index: new_index          
			};
			result = await sendRequest(
				"PUT",
				params,
				url+"/switchcards/"+tarjeta.id
			);
			if(result != "ok") {
				this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
			}
        },
	},
	mounted() {
		this.getNotificaciones();
		this.countNotificaciones();		

		const channel = createChannelConection();
		
		window.Echo.channel(channel)
			.listen("Notificacion", (e) => {
				this.addNotification(e.data);
				this.countNotificaciones();
				this.playNotificationSound();
			});
	}
};

</script>

<style>
@keyframes vibrate {
	0% { transform: translateX(0); }
	25% { transform: translateX(-2px); }
	50% { transform: translateX(2px); }
	75% { transform: translateX(-2px); }
	100% { transform: translateX(0); }
  }
  
.pi-vibrate {
	animation: vibrate 0.3s linear infinite;
}

.custom-bell {
  font-size: 18px; 
  color: #fff;
  top: 10px; 
}

.no-notification span{
  display: none;
}
</style>