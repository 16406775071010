<template>
    <Dialog v-model:visible="dialog" :modal="true" class="" @show="adjustScroll" @hide="resetScroll" :style="{ width: '60vw' }" :breakpoints="{ '960px': '75vw', '640px': '85vw', '400px': '95vw' }">
        <template #header>
        <Inplace :closable="true" >
            <template #display>
                <span class="tarjeta-titulo font-bold" style="font-size: medium"><i class="pi pi-credit-card"></i>{{ tarjeta.id }}. {{ card.titulo || 'Sin título' }}</span>
            </template>
            <template #content>
                <InputText id="titulo" v-model.trim="card.titulo" maxlength="150" required="true" :class="{'p-invalid': submitted && !card.titulo}" autofocus />
                <small class="p-error" v-if="submitted && !card.titulo">El título es requerido.</small>
            </template>
        </Inplace>
        </template>
        <div class="col-12">
            <div class="tablero">
                <span>En Tablero <span class="tablero-name">{{ tableroNombre }}</span> <ChangeColumn :card="card" :tablero_id="tablero_id" @datos="recibirValor"/> </span>
                <div class="subtitle-card-wrapper">
                    <span class="tarjeta-subtitulo mt-3 pr-10" for="detalles"><i class="pi pi-exclamation-circle"></i> Prioridad</span>
                    <div class="prioridad-rb">
                        <div class="flex flex-wrap gap-2">
                            <div class="flex align-items-center">
                                <RadioButton v-model="card.prioridad" inputId="prioridad" name="prioridad" value="Alta" class="alta-rb"/>
                            </div>
                            <div class="flex align-items-center">
                                <RadioButton v-model="card.prioridad" inputId="prioridad" name="prioridad" value="Media" class="media-rb"/>
                            </div>
                            <div class="flex align-items-center">
                                <RadioButton v-model="card.prioridad" inputId="prioridad" name="prioridad" value="Baja" class="baja-rb"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedResponsableId" class="subtitle-card-wrapper">
                    <span class="tarjeta-subtitulo mt-3 pr-10" for="detalles"><i class="pi pi-user"></i> Responsable</span>
                    <!-- <span v-if="usuarios" class="responsable-initials">{{ usuarios.find(user => user.id === selectedResponsableId).iniciales }}</span> -->
                    <span v-if="usuarios" class="responsable-initials" @click="toggleShowResponsable">{{ usuarios.find(user => user.id === selectedResponsableId).iniciales }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 flex">
            <div class="col-9">
                <div class="grid">
                    <!-- <div class="field"> -->
                    <div class="col-12 p-fluid fechas">
                        <div class="flex fechas-block">
                            <div class="col-6">
                                <p class="tarjeta-subtitulo" for="detalles"><i class="pi pi-calendar"></i> Fecha de Inicio</p>
                                <Button @click="mostrarCalendarioInicio" :label=fecha_inicio text severity="info"/>
                                <Calendar v-model="card.fechainicio" @date-select="onDateInicio" :showIcon="false" :showOnFocus="false" :inline="true" :minDate="minDate" v-if="mostrar_inicio" dateFormat="yy-mm-dd"  showTime/>
                            </div>
                            <div class="col-6">
                                <p class="tarjeta-subtitulo" for="detalles"><i class="pi pi-calendar"></i> Fecha de Compromiso</p>
                                <Button @click="mostrarCalendarioCompromiso" :label=fecha_compromiso text severity="danger" :disabled=compromiso_disabled  />
                                <Calendar v-model="card.fechacompromiso" @date-select="onDateCompromiso" :showIcon="false" :showOnFocus="false" :inline="true" :minDate=card.fechainicio v-if="mostrar_compromiso" dateFormat="yy-mm-dd" showTime :disabled=compromiso_disabled />
                            </div>
                        </div>
                        <p class="tarjeta-subtitulo" for="detalles"><i class="pi pi-align-justify"></i> Descripción</p>
                        <div class="card-description">
                            <Textarea id="detalles" v-model="card.descripcion" :class="{'p-invalid': submitted && !card.descripcion}" rows="5"/>
                        </div>
                        <small class="p-error" v-if="submitted && !card.descripcion">La descripción es requerido.</small>
                    </div>                    
                </div>
                <div>
                    <p for="task" class="tarjeta-subtitulo mt-3"><i class="pi pi-check-square"></i> Checklists</p>
                    <div class="tasks-block">        
                        <div class="col-12">
                            <CheckList :checklists="checklists_tarjeta"/> 
                        </div>
                    </div>
                </div>
                <!-- add Checklist -->
                
                <p class="tarjeta-subtitulo" for="detalles"><i class="pi pi-history"></i> Bitácora</p>
                <!-- Bitacoras-->
                <div class="bitacora-block">        
                    <Bitacora :tarjeta="tarjeta" :submittedComentario="submittedComentario" />
                </div>
                <!-- Comentarios-->
                <div class="add-comment-block">
                    <div class="flex align-items-center gap-1">
                        <div class="col-11 p-fluid">
                            <InputText  v-model="comentario" autocomplete="off" placeholder="Agregar comentario"/>
                        </div>
                        <div class="col-1">
                            <Button :disabled="!formularioValido || comentario.length > 500" @click="addBitacora()" icon="pi pi-send" />
                        </div> 
                    </div>
                    <span class="flex flex-column md:align-items-end font-medium text-secondary text-sm" :class="{'text-red-500': comentario.length > 500}">{{ comentario.length }} / 500</span>
                </div>
                <!-- Bitacoras-->
            </div>
            <div class="col-3">
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Categoría" icon="pi pi-tag" text @click="toggleShowCategory"/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Responsable" icon="pi pi-user" text @click="toggleShowResponsable"/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Observador(es)" icon="pi pi-users" text @click="toggleShowObservator"/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Relaciones" icon="pi pi-arrows-h" text @click="toggleShowRelaciones"/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Fechas" icon="pi pi-calendar" text/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Adjuntos" icon="pi pi-file" text/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Conducto" icon="pi pi-code" text @click="toggleShowConducto"/>
                </div>
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Checklist" icon="pi pi-check" text @click="toggleShowAddTask"/>
                </div>
                <RecordatorioTarjeta :tarjeta="tarjeta" />
                <div class="col-12 no-side-padding">
                    <Button class="full-width" label="Acciones" icon="pi pi-cog" text @click="toggleShowActions"/>
                </div>
                <OverlayPanel ref="showresponsable">
                    <div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="detalles"><i class="pi pi-user"></i> Asignar a</p>
                            <div class="mb-3">
                                <Dropdown 
                                    v-model="this.card.responsable" 
                                    :options="this.usuarios" 
                                    filter 
                                    optionValue="id"
                                    optionLabel="name"
                                    class="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showobservator">
                    <div>
                        <p class="tarjeta-subtitulo mb-3" for="detalles"><i class="pi pi-users"></i> Asignar Observador(es)</p>
                        <div class="mb-3">
                            <MultiSelect
                                v-model="this.card.observadores"
                                :options="this.usuarios"
                                optionLabel="iniciales"
                                filter
                                :filterFields="['name','iniciales']" 
                                :filterMatchMode="'contains'"
                                display="chip"
                                placeholder="Observador(es)"
                                class="w-full"
                            >
                                <template #option="slotProps">
                                    <div class="flex items-center">
                                        <div>{{ slotProps.option.name }}</div>
                                    </div>
                                </template>
                                <template #footer>
                                    <div class="py-2 px-4">
                                        <b>{{ selectedObservadors ? selectedObservadors.length : 0 }}</b> item{{ (selectedObservadors ? selectedObservadors.length : 0) > 1 ? 's' : '' }} selected.
                                    </div>
                                </template>
                            </MultiSelect>
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showrelaciones">
                    <div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="detalles"><i class="pi pi-user"></i> Cliente:</p>
                            <div class="mb-3">
                                <AutoComplete 
                                    v-model="selectedCliente" 
                                    :suggestions="filteredClientes" 
                                    @complete="searchClientes"
                                    field="nombreCompleto"
                                    class="w-full"
                                    placeholder="Escribe para buscar un cliente"
                                    @change="onClienteSelected"
                                />
                            </div>
                        </div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="detalles"><i class="pi pi-user"></i> Poliza:</p>
                            <div class="mb-3">
                                <AutoComplete 
                                    v-model="selectedPoliza" 
                                    :suggestions="filteredPolizas" 
                                    @complete="searchPolizas"
                                    field="no_poliza"
                                    class="w-full"
                                    placeholder="Escribe para buscar una Póliza"
                                    @change="onPolizaSelected"
                                >
                                    <template #option="slotProps">
                                        <div>
                                        {{ slotProps.option.no_poliza.replace(/\s+/g, '') }} - {{ slotProps.option.concepto }}
                                        </div>
                                    </template>
                                </Autocomplete>
                            </div>
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showcategory">
                    <div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="categoria_id"><i class="pi pi-tag"></i> Categoría</p>
                        </div>
                        <div class="mb-3">
                            <Dropdown 
                                v-model="this.card.categoria_nombre" 
                                :options="this.categorias_select" 
                                filter 
                                optionValue="nombre"
                                optionLabel="nombre"
                                class="w-full"
                                @change="getRamos"
                            />
                        </div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="ramo_id"><i class="pi pi-tag"></i> Ramo </p>
                        </div>
                        <div class="mb-3">
                            <Dropdown 
                                v-model="this.card.ramo_id" 
                                :options="this.ramos" 
                                filter 
                                optionValue="id"
                                optionLabel="nombre"
                                class="w-full"
                                @change="getSubramos"
                            />
                        </div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="subramo_id"><i class="pi pi-tag"></i> Subramo </p>
                        </div>
                        <div class="mb-3">
                            <Dropdown 
                                v-model="this.card.subramo_id" 
                                :options="this.subramos" 
                                filter 
                                optionValue="id"
                                optionLabel="nombre"
                                class="w-full"
                                @change="getIdcategoria"
                            />
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showconducto">
                    <div>
                        <div v-if="this.tipoTablero.includes('Prospección')">
                            <div>
                                <p class="tarjeta-subtitulo mb-3" for="conducto"><i class="pi pi-arrows-h"></i> Conducto</p>
                            </div>
                            <Dropdown v-model="card.conducto" :options="conductosTarjeta" optionLabel="name" optionValue="id" placeholder="seleccione una conducto" class="md:w-15rem" />                  
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showactions">
                    <div>
                        <div class="mb-3">
                            <Button v-if="can('tarjetas.clonar') && card.id" label="Clonar" icon="pi pi-clone" text @click="toggleClonar" />
                        </div>
                        <div>
                            <Button v-if="can('tarjetas.bloquear') && card.id"  :label="label_bloqueo" :icon="icon_bloqueo" text @click="toggleBloquear"/>
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref="showaddtask">
                    <div>
                        <div>
                            <p class="tarjeta-subtitulo mb-3" for="checklist_id"><i class="pi pi-check"></i> Agregar Checklist</p>
                        </div>
                        <div class="mb-3">
                            <p>Titulo de checklist(s)</p>
                            <Textarea v-model="task" rows="4" cols="30" autocomplete="off" placeholder="Puedes agregar varios checklist, uno por línea"/>
                        </div>
                        <div class="mb-3">
                            <Button icon="pi pi-list" class="w-full" label="Agregar a checklist" @click="toggle" text :disabled="!formularioValido" severity="info" />
                        </div>
                        <div>
                            <Button icon="pi pi-plus" class="w-full" label="Crear nuevo checklist(s)" severity="danger" :disabled="!formularioValido" @click="addTask()" link />
                        </div>
                    </div>
                </OverlayPanel>
            </div>
        </div>

        <template #footer>
            <Button v-if="can('tarjetas.destroy') && card.id" label="Eliminar" icon="pi pi-trash" text @click="confirmDeleteTarjeta(tarjeta)" />
            <Button label="Cancelar" icon="pi pi-times" text @click="hideDialogTarjeta"/>
            <Button label="Guardar" :disabled="!formularioValido" icon="pi pi-check" text @click="saveTarjeta" />
        </template>
    </Dialog>
    <OverlayPanel ref="op">
        <div class="flex flex-column gap-3 w-25rem">
            <div class="field">
                <Dropdown v-model="checklist" :options="checklists" optionLabel="nombre" optionValue="id" placeholder="Selecciona checklist" />
            </div>
            <div>
                <Button v-if="can('itemstarjeta.import')" label="Agregar" :disabled="!formularioChecklistValido" icon="pi pi-plus" text @click="addChecklist" />
            </div>
        </div>
    </OverlayPanel>

    <OverlayPanel ref="bloquear" :showCloseIcon=true>
        <div class="flex flex-column gap-3 w-50rem">
            <span class="p-float-label">            
                <Textarea v-model="motivo" autocomplete="off" :class="{'p-invalid': submitted && !motivo}" rows="3" cols="50"/>            
                <label for="task">Motivo</label>                 
            </span>
            <small class="p-error" v-if="submitted && !motivo">El motivo es requerido.</small><br/>
        </div>

        <div>
            <Button label="Guardar" text @click="bloqueo()" />
        </div>
    </OverlayPanel>
    <OverlayPanel ref="clonar" :showCloseIcon=true>
        <p class="tarjeta-subtitulo mb-3" for="tablero"><i class="pi pi-users"></i> Seleccione el tablero</p>
        <div v-if="selectedObservadors !== null" class="mb-3">
        <Dropdown 
            v-model=this.tablero
            :options="tableros" 
            filter
            optionValue="id"
            optionLabel="nombre"
            class="w-full md:w-14rem"
            @change="getCategoriasClonar"
        />
        </div>
        <div>
        <p class="tarjeta-subtitulo mb-3" for="categoria_nombre"><i class="pi pi-tag"></i> Categoría</p>
        </div>
        <div class="mb-3">
            <Dropdown 
                v-model="this.clonar.categoria_nombre" 
                :options="this.categorias_clonar" 
                filter 
                optionValue="nombre"
                optionLabel="nombre"
                class="w-full"
                @change="getRamosClonar"
            />
        </div>
        <div>
        <p class="tarjeta-subtitulo mb-3" for="ramo_id"><i class="pi pi-tag"></i> Ramo </p>
        </div>
        <div class="mb-3">
            <Dropdown 
                v-model="this.clonar.ramo_id" 
                :options="this.ramos_clonar" 
                filter 
                optionValue="id"
                optionLabel="nombre"
                class="w-full"
                @change="getSubramosClonar"
            />
        </div>
        <div>
        <p class="tarjeta-subtitulo mb-3" for="subramo_id"><i class="pi pi-tag"></i> Subramo </p>
        </div>
        <div class="mb-3">
            <Dropdown 
                v-model="this.clonar.subramo_id"
                :options="this.subramos_clonar"
                filter 
                optionValue="id"
                optionLabel="nombre"
                class="w-full"
                @change="getIdcategoriaClonar"
            />
        </div>
        <div>
            <Button label="Guardar" text @click="clonarTarjeta()" />
        </div>
    </OverlayPanel>
    <Dialog v-model:visible="deleteDialogTarjeta" :style="{width: '450px'}"  :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tarjeta">Realmente desea eliminar la tarjeta <b>{{card.titulo}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" text @click="deleteDialogTarjeta = false"/>
            <Button label="Si" icon="pi pi-check" text @click="deleteTarjeta" />
        </template>
    </Dialog>    
</template>
<script>
import CheckList from "@/components/CheckList";
import Bitacora from "@/components/BitacoraTarjeta";
import { sendRequestList, sendRequest, userCan} from '@/helper';
import ChangeColumn from '@/components/ChangeColumn.vue';
import RecordatorioTarjeta from '@/components/RecordatorioTarjeta.vue';

const url = process.env.VUE_APP_BASE_URL;
export default {
    emits: ['datos'],
    components: {
      CheckList,
      Bitacora,
      ChangeColumn,
      RecordatorioTarjeta
    },
    props: {
        tarjeta: {
            type: Object,
            required: true
        },
        tablero_id: {
            type: String,
            required: true
        },        
        tarjetaDialog: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return{
            submitted: false,
            dialog: false,
            card: {},
            categorias: null,
            categorias_trees: null,
            checklists: null,
            checklist: null,
            tipoTablero: "",
            tableroNombre: "",
            mostrar_inicio: false,
            mostrar_compromiso: false,
            compromiso_disabled: false,
            filtered: null,
            filterType: "",
            usuarios: null,
            checklists_tarjeta: [],
            submittedComentario: false,
            comentario: "",
            task: "",
            tasks: [],
            conductosTarjeta: null,
            label_bloqueo: "",
            icon_bloqueo: "",
            tablero_nombre: "",
            motivo: null,
            deleteDialogTarjeta: false,
            clonarDialogTarjeta: false,
            filteredUsers: [],
            selectedObservadors: null,
            selectedResponsableId: null,
            selectedResponsable: null,
            selectedClienteId: null,
            selectedCliente: null,
            selectedPolizaId: null,
            selectedPoliza: null,
            filteredClientes: [],
            filteredPolizas: [],
            fecha_inicio: "",
            fecha_compromiso: "",
            minDate: null,
            categorias_select: null,
            quattro_vendedor: null,
            ramos: null,
            subramos: null,
            tableros: null,
            tablero: null,
            categorias_clonar: null,
            ramos_clonar: null,
            subramos_clonar: null,
            clonar: {}
        }
    },
    methods: {
        adjustScroll() {
            const maxScrollRight = document.documentElement.scrollWidth - window.innerWidth;        
            window.scrollTo({ left: maxScrollRight, behavior: 'auto' });
        },
        resetScroll() {        
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
            this.$emit('datos', { visible: false });
            this.hideDialogTarjeta();
        },
        async getCategorias() {
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/categoriaslist?tablero="+tablero_id);
            this.categorias = result.data.data;
            this.loading = true;
        },
        async getGroupcategorias() {
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/categoriaselect?tablero="+tablero_id);
            this.categorias_select = result.data;
            this.loading = true;
        },
        async getRamos() {
            this.card.ramo_id = null;
            this.card.subramo_id = null;
            this.card.categoria_id = null;
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/ramosselect?tablero="+tablero_id+"&nombre="+this.card.categoria_nombre);
            this.ramos = result.data.data;
            this.loading = false;
        },
        async getSubramos() {
            this.card.subramo_id = null;
            this.card.categoria_id = null;
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/subramosselect?tablero="+tablero_id+"&ramo="+this.card.ramo_id+"&nombre="+this.card.categoria_nombre);
            this.subramos = result.data.data;
            this.loading = false;
        },
        async getIdcategoria() {
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/categoriabynombre?tablero="+tablero_id+"&subramo="+this.card.subramo_id+"&nombre="+this.card.categoria_nombre);
            this.card.categoria_id = result.data.data.id;
            this.loading = false;
            this.watchCategoria();
        },
        async getCategoriasClonar() {
            const tablero_id = this.tablero;
            this.clonar.categoria_nombre = null;
            this.clonar.ramo_id = null;
            this.clonar.subramo_id = null;
            this.clonar.categoria_id = null;
            const result = await sendRequestList(url+"/categoriaselect?tablero="+tablero_id);
            this.categorias_clonar = result.data;
        },
        async getRamosClonar() {
            this.clonar.ramo_id = null;
            this.clonar.subramo_id = null;
            this.clonar.categoria_id = null;
            const tablero_id = this.tablero;
            const result = await sendRequestList(url+"/ramosselect?tablero="+tablero_id+"&nombre="+this.clonar.categoria_nombre);
            this.ramos_clonar = result.data.data;
        },
        async getSubramosClonar() {
            this.clonar.subramo_id = null;
            this.clonar.categoria_id = null;
            const tablero_id = this.tablero;
            const result = await sendRequestList(url+"/subramosselect?tablero="+tablero_id+"&ramo="+this.clonar.ramo_id+"&nombre="+this.clonar.categoria_nombre);
            this.subramos_clonar = result.data.data;
        },
        async getIdcategoriaClonar() {
            const tablero_id = this.tablero;
            const result = await sendRequestList(url+"/categoriabynombre?tablero="+tablero_id+"&subramo="+this.clonar.subramo_id+"&nombre="+this.clonar.categoria_nombre);
            this.clonar.categoria_id = result.data.data.id;
        },
        async getChecklists() {            
            this.loading = true;
            const result = await sendRequestList(url+"/checklist");
            this.checklists = result.data.data;
            this.checklist = null;
        },
        watchCategoria() {
            const categoria = this.buscarCategoriaId(this.card.categoria_id);            
            if (categoria && categoria.tiempo_respuesta != null) {
                this.calculaFechaCompromiso(categoria.tiempo_respuesta, categoria.unidad_tiempo);          
                this.compromiso_disabled = true;
            } else {
                this.compromiso_disabled = false;
            }
        },
        async getTipoTablero() {
            const result = await sendRequest('GET', {},url+"/tableros/"+this.tablero_id, true);
            const tablero = {...result.data};                      
            this.tipoTablero = tablero.nombres_categorias;
            this.tableroNombre = tablero.nombre;
        },
        async getTableros(){
          const result = await sendRequestList(url+"/tableroslist");
          this.tableros = result.data.data; 
        },
        async getConductosTarjeta(){
            this.loading = true;
            const result = await sendRequestList(url+"/conductostarjetas");
            this.conductosTarjeta = result.data;            
        },
        onDateInicio() {
            this.fecha_inicio = this.fechaFormateada(this.card.fechainicio);
            this.mostrar_inicio = false;

            if(this.card.categoria_id) {
                this.watchCategoria();
            }
        },
        mostrarCalendarioInicio() {
            this.mostrar_inicio = !this.mostrar_inicio;
            if (this.mostrar_inicio == true && this.mostrar_compromiso == true) {
                this.mostrar_compromiso= false;
            }
        },
        buscarCategoriaId(id) {
            const categoria_id = parseInt(id);            
            if (this.categorias) {
                return this.categorias.find(objeto => objeto.id === categoria_id);
            }
            return {};
        },
        calculaFechaCompromiso(valor, unidad ) {
            const fecha = new Date(this.card.fechainicio);
            switch (unidad) {
            case "minutos":
                fecha.setMinutes(fecha.getMinutes() + valor);
                break;
            case "horas":
                fecha.setHours(fecha.getHours() + valor);
                break;
            case "días":
                fecha.setDate(fecha.getDate() + valor);
                break;
            case "semanas":
                fecha.setDate(fecha.getDate() + (valor * 7));
                break;
            case "meses":
                fecha.setMonth(fecha.getMonth() + valor);
                break;
            default:
                break;
            }

            this.card.fechacompromiso = fecha;
            this.fecha_compromiso = this.fechaFormateada(this.card.fechacompromiso);
        },
        onDateCompromiso() {
            this.fecha_compromiso = this.fechaFormateada(this.card.fechacompromiso);
            this.mostrar_compromiso = false;
        },
        mostrarCalendarioCompromiso() {
            this.mostrar_compromiso = !this.mostrar_compromiso;
            if (this.mostrar_inicio == true && this.mostrar_compromiso == true) {
                this.mostrar_inicio= false;
            }
        },
        search(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtered = [...this.usuarios];
                } else {
                    this.filtered = this.usuarios.filter((user) => {                    
                        return user.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        can(action) {
            return userCan(action);
        },
        hideDialogTarjeta() {
            this.dialog = false;
            this.submitted = false;
            this.card = {};
            this.checklists_tarjeta= [];
            this.filteredUsers= [];
            this.selectedObservadors= [];
            this.selectedResponsableId= null;
            this.selectedResponsable= null;
            this.selectedClienteId= null;
            this.selectedCliente= null;
            this.selectedPolizaId= null;
            this.selectedPoliza= null;
        },
        async saveTarjeta(close = true) {
            this.submitted = true;
            var message = "";
            var result = "";
            var fecha_inicio = "";
            var fecha_compromiso = "";

            if (this.card.fechainicio != null) {
                fecha_inicio = this.fechaFormateada(this.card.fechainicio);

                if (this.card.fechacompromiso != null && this.card.fechainicio.getTime() > this.card.fechacompromiso.getTime()) {
                    this.card.fechacompromiso = this.card.fechainicio;
                }
            }        
            
            if (this.card.fechacompromiso != null) {                
                fecha_compromiso = this.fechaFormateada(this.card.fechacompromiso);
            }          
            var observadores = [];
            if(this.card.observadores) {
                this.card.observadores.forEach(
                    user => observadores.push(user.id)
                )
            }
            
            var params = {
                titulo: this.card.titulo.trim(),
                descripcion: this.card.descripcion,            
                posicionColumna: 1,
                prioridad: this.card.prioridad,          
                nivel: 0,          
                conducto: this.card.conducto,
                estatus: "activa",          
                categoria_id: this.card.categoria_id,
                tablero_id: this.tablero_id,
                responsable: this.card.responsable,
                observadores: observadores,
                cliente_id: this.selectedClienteId,
                poliza_id: this.selectedPolizaId,
                fecha_inicio: fecha_inicio,
                fecha_compromiso: fecha_compromiso
            };
            
            if(this.card.id) {
                params.nivel = this.card.nivel;
                params.estatus = this.card.estatus;
                message = "Tarjeta actualizada";
                result = await sendRequest(
                    "PUT",
                    params,
                    url+"/tarjetas/"+this.card.id,
                    true
                );
            } else {
                message = "Tarjeta registrada";
                result = await sendRequest(
                    "POST",
                    params,
                    url+"/tarjetas",
                    true
                );
            }        

            if (result.data) {
                this.$toast.add({severity:'success', summary: 'Éxito', detail: message, life: 3000});
            if (close) {
                this.checklists_tarjeta= [];
                this.hideDialogTarjeta();
            } else {
                this.card = {...result.data};
            }         
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
            }                
        },
        change_labels_bloqueo() {
            if (this.card.estatus == "activa") {
                this.label_bloqueo = "Bloquear";
                this.icon_bloqueo="pi pi-ban";
            }

            if (this.card.estatus == "bloqueada") {
                this.label_bloqueo = "Desbloquear";
                this.icon_bloqueo="pi pi-unlock";
            }
        },
        async bloqueo() {
            this.submitted = true;
            this.submittedComentario = false;        
            if (this.motivo && this.motivo.trim() !== '') {
                var des_bloqueo = "";
                if (this.label_bloqueo == "Bloquear") {
                    des_bloqueo = "Bloqueo: ";
                } else {
                    des_bloqueo = "Desbloqueo: ";
                }
                const params = {
                    descripcion: des_bloqueo+this.motivo,
                    tarjeta_id: this.tarjeta.id,
                };
                var error = false;

                const result = await sendRequest("POST", params, url+"/bitacoras");
                this.submittedComentario = true;
                if (result == "ok") {
                    const tarjeta = await sendRequest("PUT", {}, url+"/bloqueartarjeta/"+this.tarjeta.id, true);
                    
                    if (tarjeta.data) {
                        this.motivo = "";
                        this.submitted = false;
                        this.$refs.bloquear.hide();
                        this.card.estatus = tarjeta.data.estatus;                        
                        this.change_labels_bloqueo();
                    } else {
                        error = true;
                    }                
                } else {
                    error = true;
                }  

                if (error == true) {
                    this.$toast.add({severity:'error', summary: 'Error', detail: "Error al guardar el motivo", life: 3000});
                }
            }
        } ,
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        toggleAddTask(event) {
            this.$refs.addtask.toggle(event);
        },
        toggleShowCategory(event) {
            this.$refs.showcategory.toggle(event);
        },
        toggleShowResponsable(event) {
            this.$refs.showresponsable.toggle(event);
        },
        toggleShowObservator(event) {
            this.$refs.showobservator.toggle(event);
        },
        toggleShowRelaciones(event) {
            this.$refs.showrelaciones.toggle(event);
        },
        toggleShowConducto(event) {
            this.$refs.showconducto.toggle(event);
        },
        toggleShowActions(event) {
            this.$refs.showactions.toggle(event);
        },
        toggleShowAddTask(event) {
            this.$refs.showaddtask.toggle(event);
        },
        toggleBloquear(event) {
            this.$refs.bloquear.toggle(event);
        },
        async toggleClonar(event) {
            this.$refs.clonar.toggle(event);
            this.getTableros();
            this.tablero = this.tablero_id;
            this.clonar = {};
        },
        async addTask() {
            if (!this.tarjeta.id) { // si la tarjeta no esta guardada, primero se guarda la tarjeta y se asigna el tarjeta.id para poder relacionar a un checklist
                await this.saveTarjeta(false);
            }

            if (this.task.length > 0) {
                if (this.task.includes('\n')) {
                    const lineas = this.task.split('\n');
                    const lineasFiltradas = lineas.filter(linea => linea.trim() !== '');

                    lineasFiltradas.forEach((elemento) => {
                    this.tasks.push({ nombre: elemento});
                    });
                } else {
                    this.tasks.push({ nombre: this.task});
                }
                    
                const params = {
                    tarjeta_id: this.tarjeta.id,
                    activa: 1,
                    tipo: "tarjeta",
                    items: this.tasks
                };
                
                const result = await sendRequest("POST", params, url+"/itemstarjeta");
                if (result == "ok") {
                    this.tasks = [];
                    this.task = "";
                    this.getChecklistsTarjeta(this.card.id);
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: "Error al guardar la checklist", life: 3000});
                }         
            }
            
        },
        async getUsuarios(){
            this.loading = true;
            const result = await sendRequestList(url+"/userslist");
            this.usuarios = result.data.data;
            this.selectedResponsable = this.usuarios[this.card.responsable];
        },
        async getCliente(clienteid){
            this.loading = true;
            const result = await sendRequestList(url+"/quattro_clientes/"+clienteid);
            const cliente = result.data;
            this.selectedCliente = {
                ...cliente,
                nombreCompleto: `${cliente.nombre} ${cliente.ap_pat} ${cliente.ap_mat}`
            };
        }, 
        async searchClientes(event) {
            // Aquí puedes implementar la búsqueda dinámica, por ejemplo, filtrando la lista o haciendo una consulta al servidor
            const query = event.query;
            // Reemplaza los espacios con %20 para la consulta (URL encoding)
            const encodedQuery = encodeURIComponent(query);
            // Realiza la solicitud a la API usando la búsqueda por nombre
            const result = await sendRequestList(url + `/quattro_clientes/buscacliente/` + encodedQuery);
            // Mapea la respuesta para agregar el campo nombreCompleto
            this.filteredClientes = result.data.map(cliente => ({
                ...cliente,
                nombreCompleto: `${cliente.nombre} ${cliente.ap_pat} ${cliente.ap_mat}`
            })); 
        },
        onClienteSelected(cliente) {
            this.selectedCliente = cliente.value;
            this.selectedClienteId = cliente.value.id;
        },
        async getPoliza(polizaid){
            this.loading = true;
            console.log(polizaid);
            const result = await sendRequestList(url+"/quattro_polizas/"+polizaid);
            this.selectedPoliza = result.data;
        }, 
        async searchPolizas(event) {
            // Aquí puedes implementar la búsqueda dinámica, por ejemplo, filtrando la lista o haciendo una consulta al servidor
            const query = event.query;

            let result; // Declarar la variable result fuera del bloque if
            console.log(this.selectedCliente);
            // Verifica si clienteSelectedId no es nulo
            if (this.selectedClienteId !== null) {
                // Si clienteSelectedId no es nulo, utiliza la búsqueda por el nombre de cliente
                result = await sendRequestList(url + `/quattro_polizas/buscapolxidcli/` + this.selectedClienteId);
                this.filteredPolizas = result.data.filter(poliza => 
                    poliza.no_poliza.toLowerCase().includes(query.toLowerCase()))
            } else {
                // Si clienteSelectedId es nulo, busca las pólizas que coincidan con el input
                result = await sendRequestList(url + `/quattro_polizas/buscapoliza/` + query);
                this.filteredPolizas = result.data;
            }
           
        },
        polizaTemplate(poliza) {
            // Combina no_poliza y ini_vig, y quita espacios en no_poliza
            //const noPolizaSinEspacios = poliza.no_poliza.replace(/\s+/g, '');
            return `${poliza.no_poliza} - ${poliza.fec_vig_de}`;
        },        
        onPolizaSelected(poliza) {
            this.selectedPoliza = poliza.value;
            console.log("polvalot " + poliza.value.ot);
            this.selectedPolizaId = poliza.value.ot;
        },
        async getChecklistsTarjeta(tarjeta_id) {
            const result = await sendRequestList(url+"/itemstarjeta/"+tarjeta_id);        
            this.checklists_tarjeta = result.data.data;
        },
        async addChecklist() {
            if (!this.tarjeta.id) { // si la tarjeta no esta guardada, primero se guarda la tarjeta y se asigna el tarjeta.id para poder relacionar a un checklist
                await this.saveTarjeta(false);
            }

            if (this.checklist && this.tarjeta.categoria_id && this.tarjeta.id) {
                const params = {
                    checklist_id: this.checklist,
                    tarjeta_id: this.tarjeta.id
                };
            
                await sendRequest("POST", params, url+"/itemstarjetaimport");

                this.getChecklistsTarjeta(this.tarjeta.id);

                this.$refs.op.hide();
            }
        },
        async addBitacora() {
            if (!this.tarjeta.id) { // si la tarjeta no esta guardada, primero se guarda la tarjeta y se asigna el tarjeta.id para poder relacionar a un checklist
                await this.saveTarjeta(false);
            }
            this.submittedComentario = false;

            if (this.comentario.length > 0) {
                const params = {
                    descripcion: this.comentario,
                    tarjeta_id: this.tarjeta.id,
                };

                const result = await sendRequest("POST", params, url+"/bitacoras");
                if (result == "ok") {
                    this.comentario = "";
                    this.submittedComentario = true;
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: "Error al guardar el comentario", life: 3000});
                }  
            }
        },
        fechaFormateada(fecha) {
            const anio = fecha.getFullYear();
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const dia = fecha.getDate().toString().padStart(2, '0');
            const hora = fecha.getHours().toString().padStart(2, '0');
            const minuto = fecha.getMinutes().toString().padStart(2, '0');
            
            return `${dia}/${mes}/${anio} ${hora}:${minuto}`;
        },
        fechaFormato(fechasinform) {
            let fecha = new Date(fechasinform);
            const anio = fecha.getFullYear();
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const dia = fecha.getDate().toString().padStart(2, '0');
            
            return `${dia}/${mes}/${anio}`;
        },
        formatDate(dateString) {
            const [datePart, timePart] = dateString.split(' ');
            const [day, month, year] = datePart.split('/');            
            const formattedDatePart = `${year}-${month}-${day}`;

            return `${formattedDatePart} ${timePart}`;
        },
        confirmDeleteTarjeta(tarjeta) {
            this.card = tarjeta;
            this.deleteDialogTarjeta = true;
        },
        confirmClonar(tarjeta) {
            this.card = tarjeta;
            this.clonarDialogTarjeta = true;
        },
        async deleteTarjeta() {
            const id = this.card.id;        
            const result = await sendRequest('DELETE', {id: id},url+"/tarjetas/"+id); 
            if (result === "ok") {
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Tarjeta eliminada', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
            }
            
            this.deleteDialogTarjeta = false;
            this.card = {};
            this.hideDialogTarjeta();
        },
        async clonarTarjeta() {
            const id = this.card.id;
            let categoria =  (this.clonar.categoria_id) ? this.clonar.categoria_id :  this.card.categoria_id;
            const result = await sendRequest('POST', {id: id},url+"/clonartarjeta/"+id+"?tablero="+this.tablero+"&categoria="+categoria); 
            if (result === "ok") {
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Tarjeta clonada', life: 3000});
                this.$refs.clonar.hide();
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
            }
            
            this.clonarDialogTarjeta = false;
            this.card = {};
            this.hideDialogTarjeta();
        },
        async recibirValor(datos) {            
            //this.$emit('datos', { columna: datos.columna, visible: true });
            await this.moveCard(this.tarjeta, datos.columna, 0);            
            await this.getChecklistsTarjeta(this.tarjeta.id);
        },

        async moveCard(tarjeta, new_column, new_index) {
            var result = "";        
            var params = {
                new_column: new_column,
                new_index: new_index          
            };
            result = await sendRequest(
                "PUT",
                params,
                url+"/switchcards/"+tarjeta.id,
                true
            );
            if(typeof result == "object") {
                this.card.nombre_columna = result.data.nombreColumna;                
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
            }
        },
    },
    mounted() {
        this.dialog = this.tarjetaDialog;
        if (this.tablero_id) {
            this.getCategorias();
            this.getGroupcategorias();
        }
        
    },
    watch: {
        tarjetaDialog() {
            this.dialog = this.tarjetaDialog;
            if (this.dialog == true) {
                this.getTipoTablero();
                this.getConductosTarjeta();
                this.getUsuarios();
                this.mostrar_inicio = false;
                this.mostrar_compromiso = false;
                this.compromiso_disabled = false;
            }
        },
        async tarjeta() {
            if(this.tarjeta.id) {
                this.card = this.tarjeta;
                this.selectedObservadors = [];
                this.getChecklists();
                this.getChecklistsTarjeta(this.tarjeta.id);
                this.change_labels_bloqueo();
                const result = await sendRequest('GET', {},url+"/tarjetas/"+this.tarjeta.id, true);
                const complementar_tarjeta = {...result.data};
                this.card.observadores = complementar_tarjeta.observadores;
                this.tableroNombre =  complementar_tarjeta.tablero_nombre;

                this.selectedResponsableId = this.card.responsable;
                this.selectedClienteId = complementar_tarjeta.cliente_id;
                if (this.selectedClienteId) {
                    this.getCliente(this.selectedClienteId);
                }
                this.selectedPolizaId = complementar_tarjeta.poliza_id;
                if (this.selectedPolizaId) {
                    this.getPoliza(this.selectedPolizaId);
                }

                this.card.categoria_nombre = complementar_tarjeta.categoria_nombre;
                this.card.ramo_id = complementar_tarjeta.ramo_id;
                this.card.subramo_id = complementar_tarjeta.subramo_id;
                
                const result_ramos = await sendRequestList(url+"/ramosselect?tablero="+this.tablero_id+"&nombre="+this.card.categoria_nombre);
                this.ramos = result_ramos.data.data;

                const result_subramos = await sendRequestList(url+"/subramosselect?tablero="+this.tablero_id+"&ramo="+this.card.ramo_id+"&nombre="+this.card.categoria_nombre);
                this.subramos = result_subramos.data.data;
                
                
                this.card.nombre_columna = complementar_tarjeta.nombreColumna;
                if (this.card.fecha_inicio != null) {
                    const fecha_inicio = this.formatDate(this.card.fecha_inicio);
                    this.fecha_inicio = this.card.fecha_inicio;
                    this.card.fechainicio = new Date(fecha_inicio);
                } else {
                    this.card.fechainicio = new Date();
                }
                if(this.card.fecha_compromiso != null) {
                    const fecha_compromiso = this.formatDate(this.card.fecha_compromiso);
                    this.fecha_compromiso = this.card.fecha_compromiso;
                    this.card.fechacompromiso = new Date(fecha_compromiso);
                }
                const categoria = this.buscarCategoriaId(this.card.categoria_id);
                if (categoria && categoria.tiempo_respuesta != null) {
                    this.compromiso_disabled = true;
                }
            } else {
                this.submitted = false;
                this.card = {};
                this.checklists_tarjeta = [];
                this.card.fechainicio = new Date();
                this.fecha_inicio = this.fechaFormateada(new Date());
                this.fecha_compromiso = this.fecha_inicio;
            }
            this.minDate = new Date();
        },
        tablero_id() {
            this.getCategorias();
            this.getGroupcategorias();
        }
    },
    computed: {
      formularioValido() {        
        return this.card.titulo && this.card.descripcion && this.card.categoria_id;
      },
      formularioChecklistValido() {
        return this.checklist;
      }      
    },

}
</script>
<style>
.card {
    box-shadow: none !important;
}
.high-priority {
    border: 3px solid red; 
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
}

.medium-priority {
    border: 3px solid yellow;    
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
}

.low-priority {
    border: 3px solid green;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
}
/* Aplicar estilos cuando los radios están seleccionados */
/* Estilos para el estado Alta */
.alta-rb .p-radiobutton-box {
    border-color: rgb(255, 0, 0); /* Rojo */
    border: 1px solid rgb(255, 0, 0);
    border-radius: 10px;
}
.alta-rb .p-radiobutton-box.p-highlight {
    background-color: rgb(255, 0, 0); /* Rojo */
}
.alta-rb .p-radiobutton-icon {
    background-color: #f8bfbf;
}

/* Estilos para el estado Media */
.media-rb .p-radiobutton-box {
    border-color: rgb(255, 165, 0); /* Naranja */
    border: 1px solid rgb(255, 165, 0);
    border-radius: 10px;
}
.media-rb .p-radiobutton-box.p-highlight {
    background-color: rgb(255, 165, 0); /* Naranja */
}
.media-rb .p-radiobutton-icon {
    background-color: #ffdbb5;
}

/* Estilos para el estado Baja */
.baja-rb .p-radiobutton-box {
    border-color: rgb(0, 128, 0); /* Verde */
    border: 1px solid rgb(0, 128, 0);
    border-radius: 10px;
}
.baja-rb .p-radiobutton-box.p-highlight {
    background-color: rgb(0, 128, 0); /* Verde */
}
.baja-rb .p-radiobutton-icon {
    background-color: #b7e1cd;
}

.p-dialog-header {
    padding-bottom: 0 !important;
}

.card-description {
    margin-left: 33px;
}

.tarjeta-titulo .pi {
    font-size: 1.3rem;
    margin-right: 15px;
}

.tarjeta-subtitulo {
    font-size: 1.1rem;
    font-weight: 600;
}

.tarjeta-subtitulo .pi {
    font-size: 1.1rem;
    margin-right: 15px;
}

.tablero {
    margin-left: 36px;
    padding-top: 5px;
}

.fechas {
    padding-top: 5px;
}

.fechas-block {
    margin-left: 28px;
    padding-bottom: 0;
}

.fechas .fecha-inicio, .fechas .fecha-compromiso {
    margin-bottom: 0;
}

.tablero .tablero-name {
    text-decoration: underline;
}

.tarjeta-titulo {
    text-transform: uppercase;
    font-size: 20px !important;
}

.tasks-block {
    padding-bottom: 0;
}

.bitacora-block {
    padding-bottom: 0;
}

.add-comment-block {
    padding-bottom: 0;
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}

.full-width {
    width: 100%;
}

.p-multiselect-token {
    padding: 0.375rem 0.5rem !important;
    margin-right: 0.2rem !important;
}

.p-button {
    color: #081136 !important;
    background: #ecf0fc !important;
    border: 1px solid #ecf0fc !important;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.p-dialog-footer {
    padding: 1rem 1.5rem !important;
}

.p-dialog-content {
    padding: 0 0 2rem 1.5rem !important;
}

.pr-10 {
    padding-right: 10px;
}

.prioridad-rb {
    display: inline-block;
}

.responsable-initials {
    text-decoration: underline;
}

.subtitle-card-wrapper {
    display: inline-block;
    padding-left: 30px;
}

.w-full {
    width: 100%;
}

/* Tamaño de la caja de comentarios */
.bitacora-comentario{display: flex !important; width: 100% !important;}
</style>