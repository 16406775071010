<template>
	<div class="layout-topbar">
		<router-link to="/dashboard" class="layout-topbar-logo">
			<!-- <img alt="Logo" :src="topbarImage()" /> -->
			<img src="layout/images/logo-dark.png" alt="Image"  class="" />
			<span>SINV</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<div class="slogan">
			<img src="layout/images/slogan.png" alt="Image"  class="" />
		</div>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<NotificationList />
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="toggle">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
				<Menu ref="menu" id="overlay_menu" :model="items" :popup="true" @click="logout"/>				
			</li>
		</ul>
	</div>
</template>

<script>
import { logout } from '@/auth/user';
import NotificationList from '@/components/NotificationList.vue';

export default {
	components: {
		NotificationList
	},
	data(){
		return {
            items: [
                {
                    label: 'Salir',
                    icon: 'pi pi-sign-out',
                },               
            ]
        };
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		toggle(event) {
            this.$refs.menu.toggle(event);			
        },
		logout() {
			logout();
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>

<style>
.slogan {
	width: 100%;
	text-align: center;
}
</style>