<template>
<span >en columna <a class="underline" @click="toggleShowColumnas" >{{ card.nombre_columna }}</a></span>
<OverlayPanel ref="showcolumnas">
    <div class="field">
        <label for="columna" class="mb-3">Columna</label> 
        <Dropdown v-model="columna" :options="columnas" optionLabel="nombre" optionValue="id" class="md:w-15rem" />
    </div>
    <div class="field">        
        <Button label="Mover" text @click="mover"  />
    </div>
</OverlayPanel>

</template>

<script>
import { sendRequestList } from '@/helper';


const url = process.env.VUE_APP_BASE_URL;

export default {
    props: {
        card: {
            type: Object,
            required: true
        },
        tablero_id: {
            type: String,
            required: true
        },        
    },
    emits: ['datos'],
    data(){
        return{
            columnas: [],
            columna: 0
        }
    },
    methods: {
        toggleShowColumnas(event) {
            this.$refs.showcolumnas.toggle(event);
        },
        async getColumnas() {
            const tablero_id = this.tablero_id;
            this.loading = true;
            const result = await sendRequestList(url+"/columnaslist?tablero_id="+tablero_id);
            this.columnas = result.data;
        },
        mover() {            
            this.$emit('datos', { columna: this.columna });
            this.$refs.showcolumnas.hide();
        }
    },
    mounted() {
        this.getColumnas();
        this.columna = this.card.columna_id;
    }
};
</script>
<style>
.underline {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
</style>

