<template>
    <Button text icon="pi pi-ellipsis-v" @click="toggleMenu" aria-haspopup="true" aria-controls="overlay_menu" />
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
</template>
<script>
import { sendRequest } from '@/helper';

const url = process.env.VUE_APP_BASE_URL;
export default {
    emits: ['datos'],
    props: {
        notificacion: {
            type: Object,
            required: true
        },
    },
	data(){
        return{
            items: [
                {
                    label: 'Ocultar',
                    icon: 'pi pi-eye-slash',
					command: () => {
                        this.ocultar();
                        this.$emit('datos', { ocultar: true });
					}
                }
            ]
        }
    },
    methods: {
        toggleMenu(event) {
            event.stopPropagation();
            this.$refs.menu.toggle(event);
        },
        async ocultar() {
            await sendRequest('PUT', {},url+"/ocultar_notificaciones/"+this.notificacion.id);
        },
    }
}
</script>